@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: local('Poppins'), url(./assets/fonts/Poppins-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: local('Poppins'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url(./assets/fonts/Poppins-Black.ttf) format('truetype');
  font-display: swap;
}
  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', */
      /* 'Droid Sans', 'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  }

  
  #root {
    min-height: 93vh;
  }

  